$primary: #3d245a;

.acerca {
    text-align: center;

    &__title {
        font-weight: normal;
        font-size: 20px;
        @media (min-width: 768px) {
            font-size: 30px;
            margin-bottom: 30px; } }

    &__subtitle {
        font-weight: normal;
        font-size: 16px;
        margin-top: 20px;
        @media (min-width: 768px) {
            font-size: 28px; }

        &-frag {
            color: $primary;
            text-transform: uppercase;
            font-weight: bolder;
            letter-spacing: 20px;
            font-size: 15px;
            @media (min-width: 768px) {
                letter-spacing: 20px;
                font-size: 28px; } } }
    &__img {
        width: 160px;
        @media (min-width: 768px) {
            width: 350px; } } }
