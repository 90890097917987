.main-hero {
    position: relative;

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }

    &__title {
        color: white;
        text-align: center;
        font-weight: 300;
        font-size: 15px;
        @media (min-width: 768px) {
            font-size: 60px; } }

    &__logo {
        width: 200px;
        @media (min-width: 768px) {
            width: 600px; } } }

.navigation {
    color: black;
    background-color: #633c75 !important;
    &__acceder {
        font-size: 15px;
        @media (min-width: 768px) {
            font-size: 25px; } } }
