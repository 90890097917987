$primary: #241635;
$secondary: #d3cfcf;

.footer {
    // @media (min-width: 768px)
    //     padding: 100px

    color: white;
    background-color: $primary !important;

    &__section-title {
        font-weight: 100 !important;
        font-size: 16px;
        margin-top: 30px;
        margin-bottom: 12px;
        text-align: center;
        @media (min-width: 768px) {
            text-align: left; } }

    &__section-text {
        font-size: 12px;
        text-align: justify;
        color: $secondary;
        @media (min-width: 768px) {
            font-size: 14px;
            text-align: left; } }

    &__section-social {
        text-align: center;
        @media (min-width: 768px) {
            text-align: left; } }

    &__copyright {
        font-size: 12px;
        color: $secondary; }

    &__products {
        &-text {
            font-size: 12px;
            color: $secondary;
            @media (min-width: 768px) {
                font-size: 13px; }


            a {
                // margin-top: 10px !important
                color: $secondary; } }

        &-title {
            font-size: 14px;
            font-weight: 800;
            @media (min-width: 768px) {
                font-weight: 200;
                font-size: 16px; } }

        &-img {
            width: 90px;
            @media (min-width: 768px) {
                width: 100px; } } }

    &__social {
        margin-right: 30px;
        color: white;


        &:hover {
            color: yellow; } } }
