.beneficios {
    &__title {
        font-weight: 300;
        text-align: center;
        font-size: 25px;
        @media (min-width: 768px) {
            font-size: 40px; } }

    &__text {
        font-weight: 300;
        margin-top: 30px;
        text-align: justify;
        @media (min-width: 768px) {
            font-size: 26px;
            text-align: center; }

        strong {
            font-weight: 600 !important; }

        &--frag {
            font-weight: 400;
            color: black; } }

    &__img {
        width: 1200px; } }
