$secondary: #9275ae;
.planes {
    &__container {
        text-align: center;
        border-radius: 10px;
 }        // background-color: $secondary

    &__title {
        font-size: 25px;
        @media (min-width: 768px) {
            font-size: 40px; } }

    &__subtitle {
        margin-bottom: 40px;
        font-size: 15px;
        text-align: justify;
        @media (min-width: 768px) {
            font-size: 20px;
            text-align: center; } }

    &__card {
        margin-bottom: 30px;
        border-radius: 10px;
        border-width: 4px;

        &-nombre {
            font-weight: 400;
            font-size: 30px;
            @media (min-width: 768px) {
                font-size: 40px; } }

        &-precio {
            text-align: center;
            font-weight: 100;
            font-size: 18px;
            @media (min-width: 768px) {
                font-size: 25px; } }
        &-dinero {
            color: $secondary;
            font-weight: 800;
            font-size: 35px;
            @media (min-width: 768px) {
                font-size: 50px; } }

        &-signo {
            position: relative;
            top: -10px; }

        &-caracteristicas {
            font-family: serif;
            @media (min-width: 768px) {
                font-size: 18px; } } } }
