$primary: #3d245a;
$morado: #693d9b;

.contactanos {

    &__title {
        font-size: 20px;
        @media (min-width: 768px) {
            font-size: 30px; } }

    &__subtitle {
        font-size: 14px;
        text-align: justify;
        @media (min-width: 768px) {
            text-align: center;
            font-size: 16px; } }

    input:focus, textarea:focus {
        border-color: rgba($primary, 0.8) !important;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba($primary, 0.6) !important;
        outline: 0 none !important; }

    &__info-contacto {
        color: #3b3b3b !important;
        padding: 30px;
        @media (min-width: 768px) {
            padding: 0px; }
        p {
            font-size: 17px; } }


    &__subsection-title {
        font-weight: 800 !important;
        text-align: center;
        @media (min-width: 768px) {
            font-weight: 400 !important;
            text-align: left; }
 }        // text-transform: uppercase

    &__horario {
        text-align: center;
        @media (min-width: 768px) {
            text-align: left; } }


    &__icon {
        margin-right: 18px; }

    &__boton-enviar {
        background-color: $morado;
        color: white;
        &:hover {
            color: white;
            background-color: $primary; } } }
